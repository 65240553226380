<template>
  <div>
    <TabView>
      <TabPanel header="Calendar">
        <div class="row" style="height: 900px; min-height: 900px">
          <div class="col-md-3">
            <b-calendar v-model="day" start-weekday="1" block></b-calendar>
            <div class="mt-5">
              <Card>
                <template #content>
                  <div style="
                      text-align: center;
                      font-size: 89px;
                      font-weight: bold;
                      cursor: pointer;
                    " @click="$router.push('/admin/massageorder/addNew')">
                    <i class="pi pi-plus-circle" style="font-size: 89px"></i>
                    <br />
                    Add
                  </div>
                </template>
              </Card>

              <b-button class="mt-3" block @click="getAllData()">Show hidden</b-button>
            </div>
          </div>
          <div class="col-md-9">
            <component :is="fullCalendarComponent" v-if="isFullCalendarLoaded" :options="calendarOptions" ref="calendar2" />
          </div>
        </div>
        <b-modal v-model="modal2Show" hide-footer id="modal-prevent-closing-2">
          <b-button v-if="!stopEdit" class="mt-3" block
            @click="$router.push('/admin/massageorder/edit/' + editId)">Open</b-button>
          <b-button v-if="!stopEdit" class="mt-3" block @click="
              modal2Show = false;
            $router.push('/admin/massageorder/editMode/' + editId);
            ">Edit</b-button>
          <b-button class="mt-3" v-if="stopEdit" block @click="dddAll()">Delete All</b-button>
          <b-button class="mt-3" block @click="ddd()">Delete</b-button>

          <b-button class="mt-3" block v-if="!stopEdit" @click="update('isR')">Don't show in Report</b-button>

          <b-button class="mt-3" block v-if="!stopEdit" @click="update('isC')">Don't show in Clander</b-button>
        </b-modal>
      </TabPanel>
    </TabView>

    <b-modal v-model="modalShow" id="modal-prevent-closing">
      <div class="mb-3">
        <label for="notereason" class="form-label"> Reason </label>
        <Textarea v-model="notereason" rows="2" cols="30" />
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label for="personsNumber" class="form-label"> Color </label>

          <ColorPicker v-model="color" class="w-100" />
        </div>
        <div class="col-6 mb-3">
          <label for="personsNumber" class="form-label"> Range date </label>
          <Calendar id="range" v-model="dates2" selectionMode="range" :manualInput="false" style="width: 100%" />
        </div>
      </div>

      <div class="mb-3">
        <label for="personsNumber" class="form-label"> Selected Days </label>
        <div class="field-checkbox">
          <Checkbox id="binary" v-model="allDays" :binary="true" />
          <label for="binary" style="margin-bottom: 0; margin-left: 10px">All Days</label>
        </div>
        <div v-if="!allDays" class="mt-2">
          <div style="display: inline">
            <Checkbox id="Sunday" name="city" value="Sunday" v-model="selectedDays" />
            <label for="Sunday" style="margin-bottom: 0; margin-left: 8px">Sunday</label>
          </div>
          <div style="display: inline; margin-left: 8px">
            <Checkbox id="Monday" name="city" value="Monday" v-model="selectedDays" />
            <label for="Monday" style="margin-bottom: 0; margin-left: 8px">Monday</label>
          </div>
          <div style="display: inline; margin-left: 8px">
            <Checkbox id="Tuesday" name="city" value="Tuesday" v-model="selectedDays" />
            <label for="Tuesday" style="margin-bottom: 0; margin-left: 8px">Tuesday</label>
          </div>
          <div style="display: inline; margin-left: 8px">
            <Checkbox id="Wednesday" name="city" value="Wednesday" v-model="selectedDays" />
            <label for="Wednesday" style="margin-bottom: 0; margin-left: 8px">Wednesday</label>
          </div>
          <div style="display: inline; margin-left: 8px">
            <Checkbox id="Thursday" name="city" value="Thursday" v-model="selectedDays" />
            <label for="Thursday" style="margin-bottom: 0; margin-left: 8px">Thursday</label>
          </div>
          <div style="display: inline; margin-left: 8px">
            <Checkbox id="Friday" name="city" value="Friday" v-model="selectedDays" />
            <label for="Friday" style="margin-bottom: 0; margin-left: 8px">Friday</label>
          </div>
          <div style="display: inline">
            <Checkbox id="Saturday" name="city" value="Saturday" v-model="selectedDays" />
            <label for="Saturday" style="margin-bottom: 0; margin-left: 8px">Saturday</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mb-3">
          <label for="personsNumber" class="form-label"> Minutes </label>
          <InputNumber id="horizontal" class="w-100" v-model.number="minutes" showButtons buttonLayout="horizontal"
            :step="1" decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
        </div>
        <div class="col-6 mb-3">
          <label for="personsNumber" class="form-label"> Hours </label>
          <InputText id="horizontal" class="w-100" v-model="hours" type="number" />
        </div>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right" @click="addNewObject" style="margin: 0.25rem">
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
// import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import timeGridPlugin from "@fullcalendar/timegrid";

import moment from "moment";

export default {
  components: {
  },
  data() {
    return {
      allDays: true,
      selectedDays: [],
      dates2: [],
      notereason: null,
      color: "#000000",
      minutes: 30,
      hours: "0.30",
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          resourceTimeGridPlugin,
        ],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "today",
        },
        allDaySlot: false, // Hide all-day row

        initialView: "resourceTimeGridDay",
        // initialView: 'timeGridDay',
        initialDate: new Date(),
        // navLinks: true,
        slotDuration: "00:15:00",
        slotLabelInterval: 15,
        slotMinutes: 15,
        slotLabelFormat: { hour: "numeric", minute: "2-digit", hour12: false },

        businessHours: true,
        slotMinTime: "09:00",
        slotMaxTime: "22:00",
        selectMirror: true,

        events: [],
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        resources: [],
        selectable: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        resourceLabelContent: (arg) => {
          let image = document.createElement('img');
          image.src = arg.resource.extendedProps.image;
          image.style.width = '50px'; // Set the width of the image
          image.style.height = '50px'; // Set the height of the image
          image.style.marginRight = '10px'


          let label = document.createElement('div');
          label.style.fontWeight = '700';
          label.appendChild(image);
          label.appendChild(document.createTextNode(arg.resource.title));

          return { html: label.outerHTML };
        }
      },
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},

      body: {
        usersId: null,

        adminsId: null,

        start: null,
        end: null,
      },

      userList: [],
      massageList: [],

      day: null,

      pricesList: [],
      editId: null,
      modal2Show: false,
      modalShow: false,
      stopEdit: false,
      appointmentsList: [],
      stopList: [],
    };
  },
  methods: {
    update(key) {
      let obj = {};
      obj[key] = true;

      this.modal2Show = false;
      this.$http.put(`appointments/${this.editId}`, obj).then(() => {
        this.editId = null;
        this.getData();
        this.$toast.add({
          severity: "error",
          summary: "Done Successfully",
          detail: "Update successfully",
          life: 3000,
        });
      });
    },
    dddAll() {
      this.modal2Show = false;

      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const id = String(this.editId).replace("stop_", "");
          this.$http.delete(`stoptimes/deleteAll/${id}`).then(
            () => {
              this.editId = null;
              this.getData();
              this.$toast.add({
                severity: "error",
                summary: "Done Successfully",
                detail: "Deleted successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
        },
      });
    },
    ddd() {
      this.modal2Show = false;

      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          const id = String(this.editId).replace("stop_", "");
          this.$http
            .delete(
              this.stopEdit ? `stoptimes/${id}` : `appointments/${this.editId}`
            )
            .then(
              () => {
                this.editId = null;
                this.getData();
                this.$toast.add({
                  severity: "error",
                  summary: "Done Successfully",
                  detail: "Deleted successfully",
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: err.response.data.message,
                  life: 3000,
                });
              }
            );
        },
      });
    },
    retObjVal(user, date) {
      let lock = false;

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dddd123 = new Date(date);
      const d = new Date(dddd123);
      const dayName = days[d.getDay()];

      if (user[dayName] == false) {
        lock = true;
      }

      if (user.hold && user.holdFrom) {
        var d1 = new Date(dddd123);
        var d2 = new Date(user.hold);
        var d3 = new Date(user.holdFrom);
        if (d1.getTime() < d2.getTime() && d1.getTime() > d3.getTime()) {
          lock = true;
        }
      }

      return lock;
    },
    async getData() {
      this.calendarOptions.resources = [];
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const d = new Date(this.day);
      const dayName = days[d.getDay()];

      for (const item of this.userList) {
        if (!this.retObjVal(item, d)) {
          this.calendarOptions.resources.push({
            id: item.id,
            title: item.fullName,
            image: this.$baseUploadURL + item.image,
            businessHours: {
              startTime: item[dayName + "Start"],
              endTime: item[dayName + "End"],
              daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            },
          });
        }
      }

      try {
        const res = await this.$http.post(`appointments/gatByDay?isC=0&date=${this.day}`);
        this.calendarOptions.events = res.data.data.map(item => ({
          id: item.id,
          title: `${this.$getByLang(item.pricesId.name)} (${item.pricesId.minutes} Minutes = ${item.pricesId.price} €) ${item.sexType === "MR" ? "Herr" : "Frau"}/${item.fname} ${item.lname} ${item.paymentMethod} ${item.paymentMethod === 'online' ? (item.paymentCCdone ? "Done" : "Not Complete") : ""} ${item.note || ""}`,
          start: new Date(item.start),
          end: new Date(item.end),
          resourceId: Number(item.adminsId?.id),
          allDay: false,
          backgroundColor: item.massageId?.color,
        }));

        const stopes = await this.$http.get(`stoptimes?date=${this.day}`);
        if (stopes.data && stopes.data.data) {
          this.stopList = stopes.data.data;
          stopes.data.data.forEach(item => {
            this.calendarOptions.events.push({
              id: "stop_" + item.id,
              title: item.note,
              start: new Date(item.start),
              end: new Date(item.end),
              resourceId: Number(item.adminsId?.id),
              allDay: false,
              backgroundColor: item.color,
            });
          });
        }

        const calendarApi = await this.$refs.calendar2.getApi();
        await calendarApi.changeView("resourceTimeGridDay", d);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },


    async getAllData() {
      if (this.showHidden) {
        this.getData();
        this.showHidden=false;
        return;
      }
      this.calendarOptions.resources = [];
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const d = new Date(this.day);
      const dayName = days[d.getDay()];

      for (const item of this.userList) {
        if (!this.retObjVal(item, d)) {
          this.calendarOptions.resources.push({
            id: item.id,
            title: item.fullName,
            image: this.$baseUploadURL + item.image,
            businessHours: {
              startTime: item[dayName + "Start"],
              endTime: item[dayName + "End"],
              daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            },
          });
        }
      }

      try {
        const res = await this.$http.get(`appointments?isC=1&date=${this.day}`);
        this.calendarOptions.events = res.data.data.map(item => ({
          id: item.id,
          title: `${this.$getByLang(item.pricesId.name)} (${item.pricesId.minutes} Minutes = ${item.pricesId.price} €) ${item.sexType === "MR" ? "Herr" : "Frau"}/${item.fname} ${item.lname} ${item.paymentMethod} ${item.paymentMethod === 'online' ? (item.paymentCCdone ? "Done" : "Not Complete") : ""} ${item.note || ""}`,
          start: new Date(item.start),
          end: new Date(item.end),
          resourceId: Number(item.adminsId?.id),
          allDay: false,
          backgroundColor: item.massageId?.color,
        }));

        const stopes = await this.$http.get(`stoptimes?date=${this.day}`);
        if (stopes.data && stopes.data.data) {
          this.stopList = stopes.data.data;
          stopes.data.data.forEach(item => {
            this.calendarOptions.events.push({
              id: "stop_" + item.id,
              title: item.note,
              start: new Date(item.start),
              end: new Date(item.end),
              resourceId: Number(item.adminsId?.id),
              allDay: false,
              backgroundColor: item.color,
            });
          });
        }

        const calendarApi = await this.$refs.calendar2.getApi();
        await calendarApi.changeView("resourceTimeGridDay", d);


        this.showHidden=true;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    handleEventClick(clickInfo) {
      if (!clickInfo.event.id) {
        if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
          this.body.time = null;
        }
      } else {
        this.modal2Show = true;
        this.editId = clickInfo.event.id;
        this.stopEdit = String(clickInfo.event.id).startsWith("stop_");
      }
    },
    handleDateSelect(selectInfo) {
      this.body.adminsId = selectInfo.resource.id;
      this.body.start = moment(selectInfo.startStr).format();
      this.modalShow = true;
    },
    addNewObject() {
      const list = [];
      if (this.dates2[0] != null && this.dates2[1] == null) {
        const start = moment(this.dates2[0]);
        const newDate = moment(this.body.start);
        const splittedDate = {
          year: start.get("year"),
          month: start.get("month"),
          date: start.get("date"),
          hour: newDate.get("hour"),
          minute: newDate.get("minute"),
          second: newDate.get("second"),
          millisecond: newDate.get("millisecond"),
        };

        start.set(splittedDate);
        const newstart = start;
        const end = moment(newstart).add(this.minutes, "minutes").format();

        list.push({
          start: newstart,
          end: end,
          adminsId: this.body.adminsId,
          note: this.notereason,
          color: this.color,
        });
      } else if (this.dates2[0] != null && this.dates2[1] != null) {
        const startDate = moment(this.dates2[0]);
        const endDate = moment(this.dates2[1]);
        const date = [];

        for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, "days")) {
          date.push(m.format("YYYY-MM-DD"));
        }

        date.forEach(itm => {
          const start = moment(itm);
          const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
          const d = new Date(start);
          const dayName = days[d.getDay()];

          if (this.allDays || this.selectedDays.includes(dayName)) {
            const newDate = moment(this.body.start);
            const splittedDate = {
              year: start.get("year"),
              month: start.get("month"),
              date: start.get("date"),
              hour: newDate.get("hour"),
              minute: newDate.get("minute"),
              second: newDate.get("second"),
              millisecond: newDate.get("millisecond"),
            };

            start.set(splittedDate);
            const newstart = start.format();
            const end = moment(newstart).add(this.minutes, "minutes").format();
            list.push({
              start: newstart,
              end: end,
              adminsId: this.body.adminsId,
              note: this.notereason,
              color: this.color,
            });
          }
        });
      }

      this.$http.post(`stoptimes`, list).then(
        (res) => {
          this.dates2 = [];
          this.minutes = 30;
          this.hours = "0.30";

          this.modalShow = false;
          list.forEach(item => {
            this.calendarOptions.events.push({
              id: null,
              title: "Stop Time: " + item.note,
              start: new Date(item.start),
              end: new Date(item.end),
              resourceId: Number(item.adminsId),
              allDay: false,
              backgroundColor: "#" + item.color,
            });
          });

          this.$socket.emit("send", {});
          this.$toast.add({
            severity: "success",
            summary: "Done Successfully",
            detail: "Added Successfully",
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something has gone wrong",
            life: 3000,
          });
        }
      );
    },
    async onTime() {
      try {
        const data = await this.$http.post(`appointments/gatByDay?isC=0&date=${this.day}`,{});
        const stopes = await this.$http.get(`stoptimes?date=${this.day}`);
        if ((data && data.data && this.appointmentsList.length !== data.data.length) || (stopes && stopes.data && stopes.data.length !== this.stopList.length)) {
          this.getData();
        }
      } catch (error) {
        console.error("Error on time update:", error);
      }
    },
    async loadFullCalendar() {
      if (!this.isFullCalendarLoaded) {
        this.fullCalendarComponent = () => import('@fullcalendar/vue');
        this.isFullCalendarLoaded = true;
      }
    },
  },
  created() {
    this.loadFullCalendar();
    this.$http.get(`admins`).then((res) => {
      this.userList = res.data.data;
      this.day = moment().format("YYYY-MM-DD");
    });

    // this.$http.get(`appointments`).then((res) => {
    //   this.loading = false;
    //   this.list = res.data.data;
    // });
  },
  watch: {
    day(val) {
      if (val) {
        this.getData();
      }
    },
    hours(val) {
      if (val) {
        if (Number.isInteger(Number(val))) {
          this.minutes = Number(val) * 60;
        } else {
          const [num, decimalStr] = val.toString().split(".");
          this.minutes = Number(num) * 60 + Number(decimalStr);
        }
      } else {
        this.minutes = 0;
      }
    },
  },
  mounted() {
    this.$socket.on("resved", (data) => {
      this.onTime().then(() => {});
    });
  },
};
</script>

<style>
.xxx-checkbox .p-checkbox {
  margin-bottom: 22px;
  margin-right: 5px;
}

.fc .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 825px !important; */
}

.fc-scrollgrid-section-body .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 856.296px !important; */
}
</style>
